import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useState, useContext, } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { BiSlider } from 'react-icons/bi';
import { IoMdHelpCircleOutline, IoMdArrowDropright, IoMdAdd, } from 'react-icons/io';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { SiFacebook } from 'react-icons/si';
import PermissionStore from '@stores/PermissionStore';
import SearchStore from '@stores/SearchStore';
import ClipStore from '@stores/ClipStore';
import parameters from '@constants/parameters';
import { getLang } from '@services/AppUtils';
import OnclusiveLogo from 'public/img/Onclusive_Symbol.png';
import Svg from '@commons/Svg';
import Iframe from '@commons/Iframe';
import Box from '@commons/Box';
import Divider from '@commons/Divider';
import { ClickAwayListener } from '@commons/.';
import { SubNavbarContext } from '@context/SubNavbarContext';
import UserStore from '@stores/UserStore';
import UserActionCreators from '@actions/UserActionCreators';
import useGetUnreadCountProductNews from '@api/productNews/useGetUnreadCountProductNews';
import TimeZoneIconfrom from '../TimeZoneIcon';
import { Wrapper, PopperStyled } from './NavbarScreen.styles';
function NavbarScreen(_a) {
    var _b, _c;
    var isNotAgencyPage = _a.isNotAgencyPage, currentPath = _a.currentPath, _d = _a.currentUser, currentUser = _d === void 0 ? null : _d, servicesModules = _a.servicesModules, goTo = _a.goTo, onLogOut = _a.onLogOut, selectedTimeZone = _a.selectedTimeZone;
    var formatMessage = useIntl().formatMessage;
    var location = useLocation();
    var navigate = useNavigate();
    var _e = useState('https://dev.instarsocial.com/fb/login/reputation?locale=en-GB'), loginUrl = _e[0], setLoaginUrl = _e[1];
    var _f = useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    var ROLES_ENUM = parameters.ROLES_ENUM;
    var _g = useState(''), accountHasSocialMedia = _g[0], setAccountHasMedia = _g[1];
    var isPublic = location.pathname.toLowerCase().includes('public/');
    var isErrorPage = useMatch('/authorization') || useMatch('/unexpected');
    var unreadCountProductNewsData = useGetUnreadCountProductNews({
        enabled: isNotAgencyPage && !isPublic,
    }).data;
    var hrefUserGuide = function () {
        switch (getLang()) {
            case 'en':
                return 'https://view.genial.ly/620b828b618cae0019a2b12c';
            case 'fr':
                return 'https://view.genial.ly/62137905269dd6001a06549c';
            case 'es':
                return 'https://view.genial.ly/621378f57495cc0013950184';
            case 'it':
                return 'https://view.genial.ly/62137936269dd6001a0654a8';
            case 'de':
                return 'https://view.genial.ly/621379410a3e0d0010ff8392';
            default:
                return 'https://view.genial.ly/620b828b618cae0019a2b12c';
        }
    };
    var _h = useContext(SubNavbarContext), openSubnav = _h.openSubnav, setOpenSubnav = _h.setOpenSubnav, setView = _h.setView, view = _h.view;
    var _j = useState(PermissionStore.roles || []), roles = _j[0], setRoles = _j[1];
    useEffect(function () {
        var handleSetRoles = function () {
            setRoles(PermissionStore.roles);
        };
        var onGetFbInfo = function () {
            var fbLoginPageURL = UserStore.fbLoginPageURL, accountHasSocialMediaStore = UserStore.accountHasSocialMedia;
            setLoaginUrl(String(fbLoginPageURL));
            setAccountHasMedia(String(accountHasSocialMediaStore));
        };
        UserStore.addGetCurrentUserListener(onGetFbInfo);
        PermissionStore.addChangeListener(handleSetRoles);
        return function () {
            PermissionStore.removeChangeListener(handleSetRoles);
            UserStore.removeGetCurrentUserListener(onGetFbInfo);
        };
    }, []);
    var handleClickAnalytics = function () {
        setOpenSubnav(true);
        setView('analytics');
    };
    var handleToggleSubnav = function () {
        if (!openSubnav) {
            setView('services');
        }
        if (!openSubnav)
            setOpenSubnav(function (prevState) { return !prevState; });
    };
    var renderTitle = function () {
        var _a, _b;
        return (currentUser &&
            "".concat((_a = currentUser.firstName) === null || _a === void 0 ? void 0 : _a.charAt(0)).concat((_b = currentUser.lastName) === null || _b === void 0 ? void 0 : _b.charAt(0))) ||
            'L.O';
    };
    var goToAnalytics = function () {
        navigate('/analytics?dashboardType=Insight');
    };
    var insightMenu = PermissionStore.insightMenu;
    var onChangeAccount = function () {
        navigate('/agencies', { replace: true });
        // -- TODO remove comment when appliying TS to UserActionCreators
        UserActionCreators.ResetTheme();
        localStorage.removeItem('searchModel');
        SearchStore.setShouldGetFilter(false);
        ClipStore.reset();
        setAnchorEl(null);
    };
    var goToSettings = function () {
        navigate('/settings', { replace: true });
        setAnchorEl(null);
    };
    var logout = function () {
        onLogOut();
        setAnchorEl(null);
    };
    var goToPage = function (pathName) {
        (function () {
            currentPath !== pathName && navigate(pathName, { replace: true });
        })();
    };
    var handleKeyPressEnter = function (e, type) {
        if (e.keyCode === 13) {
            switch (type) {
                case 'monitoring':
                    goToPage('/monitoring');
                    break;
                case 'kiosk':
                    goToPage('/kiosk');
                    break;
                case 'analytics':
                    (insightMenu === null || insightMenu === void 0 ? void 0 : insightMenu.length) === 1 ? goToAnalytics() : handleClickAnalytics();
                    break;
                case 'productNews':
                    goToPage('/whats-new');
                    break;
                case 'moreSolutions':
                    goToPage('/more-solutions');
                    break;
                default:
                    break;
            }
        }
    };
    return (_jsxs(Wrapper, { "$isPublic": isPublic, children: [isNotAgencyPage && !isPublic && !isErrorPage && roles.length > 0 && (_jsxs(Fragment, { children: [roles.includes(ROLES_ENUM.MONITORING_ACCESS) && (_jsx("div", { className: (currentPath === 'monitoring' && 'active--route') || 'nav-item', onClick: function () {
                            goTo('/monitoring');
                        }, onKeyUp: function (e) {
                            handleKeyPressEnter(e, 'monitoring');
                        }, role: "button", tabIndex: 0, title: formatMessage({ id: 'Header.navigation.clipping' }), children: _jsx(Svg, { icon: "monitoring", size: "x-large" }) })), roles.includes(ROLES_ENUM.ANALYTICS_ACCESS) &&
                        (insightMenu === null || insightMenu === void 0 ? void 0 : insightMenu.length) ? (_jsxs("div", { className: "nav-item__with-subnav ".concat((currentPath === 'analytics' && 'active--route') || 'nav-item'), onClick: (insightMenu === null || insightMenu === void 0 ? void 0 : insightMenu.length) === 1 ? goToAnalytics : handleClickAnalytics, onKeyUp: function (e) {
                            handleKeyPressEnter(e, 'analytics');
                        }, role: "button", tabIndex: 0, title: formatMessage({ id: 'Header.navigation.insights' }), children: [(insightMenu === null || insightMenu === void 0 ? void 0 : insightMenu.length) > 1 && (_jsx(IoMdArrowDropright, { className: "nav-item__arrow-corner", size: 16 })), _jsx(Svg, { icon: "analytics", size: "x-large" })] })) : null, roles.includes(ROLES_ENUM.KIOSK_ACCESS) && (_jsx("div", { className: (currentPath === 'kiosk' && 'active--route') || 'nav-item', onClick: function () {
                            goTo('/kiosk');
                        }, onKeyUp: function (e) {
                            handleKeyPressEnter(e, 'kiosk');
                        }, role: "button", tabIndex: 0, title: formatMessage({ id: 'Delivery.kiosque.tooltip' }), children: _jsx(Svg, { icon: "kiosk", size: "x-large" }) })), _jsxs("div", { className: (currentPath === 'whats-new' && 'active--route') || 'nav-item', onClick: function () {
                            goTo('/whats-new');
                        }, onKeyUp: function (e) {
                            handleKeyPressEnter(e, 'productNews');
                        }, role: "button", tabIndex: 0, title: formatMessage({ id: 'Delivery.ProductNews.topBar.title' }), children: [unreadCountProductNewsData && unreadCountProductNewsData > 0 ? (_jsx("div", { className: "badge", children: _jsx("span", { children: unreadCountProductNewsData }) })) : null, _jsx(Svg, { icon: "whats-new", size: "x-large" })] }), _jsx("div", { className: (currentPath === 'more-solutions' && 'active--route') ||
                            'nav-item', onClick: function () {
                            goTo('/more-solutions');
                        }, onKeyUp: function (e) {
                            handleKeyPressEnter(e, 'moreSolutions');
                        }, role: "button", tabIndex: 0, title: formatMessage({ id: 'navbarScreen.moreSolutions' }), children: _jsx(IoMdAdd, { size: "20" }) }), servicesModules.length > 0 && (_jsxs(_Fragment, { children: [_jsx("hr", { className: "navbar-screen__divider" }), _jsxs("div", { className: "".concat(openSubnav && view === 'services'
                                    ? 'nav-item__with-subnav--active'
                                    : '', " nav-item nav-item__with-subnav"), onClick: handleToggleSubnav, onKeyUp: undefined, role: "button", tabIndex: 0, title: formatMessage({
                                    id: 'Header.navigation.additionalServices',
                                }), children: [_jsx(IoMdArrowDropright, { className: "nav-item__arrow-corner", size: 16 }), _jsx(Svg, { icon: "more-features", size: "x-large" })] })] }))] })), _jsxs("div", { className: "navbar-screen__bottom-section", children: [!isPublic && !isErrorPage && (_jsx("a", { href: hrefUserGuide(), className: "help-icon-link", title: formatMessage({
                            id: 'global.userGuide',
                        }), target: "_blank", rel: "noreferrer", children: _jsx(IoMdHelpCircleOutline, { size: "22", className: "bottom-section__icons" }) })), _jsx(TimeZoneIconfrom, { isPublic: isPublic || isErrorPage, selectedTimeZone: selectedTimeZone, windowWidth: 1000 }), _jsx(ClickAwayListener, { onClickAway: function () {
                            setAnchorEl(null);
                        }, children: _jsxs("div", { children: [!isPublic && !isErrorPage && (_jsx("button", { className: "menu__button", onClick: function (e) {
                                        setAnchorEl(e.currentTarget);
                                    }, title: formatMessage({ id: 'Header.accountMenu.settings' }), children: renderTitle() })), _jsx(PopperStyled
                                // eslint-disable-next-line
                                // @ts-ignore
                                , { 
                                    // eslint-disable-next-line
                                    // @ts-ignore
                                    elevation: 0, anchorEl: anchorEl, open: Boolean(anchorEl), placement: "right-end", children: _jsxs("div", { className: "popper-content", children: [_jsx("div", { children: _jsxs("div", { className: "c-account-infos", children: [_jsx("div", { className: "c-account-infos-logo", children: (currentUser &&
                                                                "".concat((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName) === null || _b === void 0 ? void 0 : _b.charAt(0)).concat((_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName) === null || _c === void 0 ? void 0 : _c.charAt(0))) ||
                                                                '' }), _jsxs("div", { className: "c-account-infos-text", children: [_jsx("span", { children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.profileName) || '' }), _jsxs("div", { className: "user-firstName-lastName", children: [(currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName) || '', ' ', (currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName) || ''] })] })] }) }), (currentUser === null || currentUser === void 0 ? void 0 : currentUser.accountName) && (_jsxs(_Fragment, { children: [_jsx(Divider, { margin: "small" }), _jsxs("div", { className: "c-account-infos", children: [_jsx("div", { className: "c-account-infos-logo", children: _jsx("img", { src: OnclusiveLogo, alt: "account-logo" }) }), _jsx("span", { className: "user-firstName-lastName", children: currentUser.accountName || '' })] })] })), _jsx(Divider, { margin: "small" }), _jsxs("ul", { children: [(accountHasSocialMedia === null || accountHasSocialMedia === void 0 ? void 0 : accountHasSocialMedia.toLowerCase()) === 'true' && (_jsxs(_Fragment, { children: [_jsxs("li", { className: "facebook__item", children: [_jsx(SiFacebook, { size: "16px" }), _jsxs(Box, { display: "flex", flexDirection: "column", children: [_jsx(FormattedMessage, { id: "Settings.increaseSocialMediaCoverage" }), _jsx(Iframe, { className: "iframe_facebook", id: "iframe_facebook", src: loginUrl, title: formatMessage({
                                                                                    id: 'Header.accountMenu.facebookCoverageTitle',
                                                                                }) })] })] }), _jsx(Divider, { margin: "small" })] })), isNotAgencyPage && (_jsxs("li", { className: location.pathname === '/settings' ? 'is-selected' : '', children: [_jsx(BiSlider, { size: "16px" }), _jsx("button", { className: "c-account__logout-btn", onClick: goToSettings, children: _jsx(FormattedMessage, { id: "Settings.goToSettings" }) })] })), isNotAgencyPage && (_jsxs(_Fragment, { children: [_jsx("li", { className: location.pathname === '/agencies' ? 'is-selected' : '', children: _jsxs("button", { className: "c-account__logout-btn", onClick: onChangeAccount, children: [_jsx(HiOutlineSwitchHorizontal, { size: "16px", className: "svg-account-list" }), formatMessage({
                                                                            id: 'Header.accountMenu.backAgencyPage',
                                                                        })] }) }), _jsx(Divider, {})] })), _jsx("li", { children: _jsxs("button", { className: "c-account__logout-btn", onClick: logout, onKeyUp: undefined, children: [_jsx(Svg, { icon: "logout", size: "medium" }), _jsx(FormattedMessage, { id: "Settings.logout" })] }) })] })] }) })] }) })] })] }));
}
export default NavbarScreen;
