var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import DOMPurify from 'dompurify';
import ActionTypes from 'constants/actionTypes';
import parameters from 'constants/parameters';
import moment from 'moment';
import { convertFromTimeStringToDateTime, convertMediumEnumtoMediumName, createDateAsUTC, getLang, } from 'services/AppUtils';
import * as DateUtils from 'services/DateUtils';
import BaseStore from 'stores/BaseStore';
import ClipSelectionStore from 'stores/ClipSelectionStore';
import UserStore from './UserStore';
var DOCUMENT_TYPE_ENUM = parameters.DOCUMENT_TYPE_ENUM;
var CONSUMPTION_EVENT = 'consumption';
var ADD_CLIP_EVENT = 'addClip';
var ADD_CONSUMPTION_EVENT = 'addConsumptionEvent';
var ADD_EVALUATE_CONSUMPTION_EVENT = 'addEvaluateConsumption';
var GET_ALL_CLIPS_EVENT = 'getAllClips';
var DENY_ARTICLE_EVENT = 'denyArticle';
var GET_CLIP_SELECTIONS_BY_CLIP_IDS_EVENT = 'getClipSelectionsByClipIds';
var GET_NEXT_CLIPS_EVENT = 'getNextClips';
var GET_RELATED_ARTICLES_EVENT = 'getRelatedArticles';
var SUCCESSFULL_CATEGORIZE_EVENT = 'successfullCategorize';
var SUCCESSFULL_UNIT_PDF_EVENT = 'successunitPDF';
var SUCCESSFULL_SAVE_ORDER_EVENT = 'successfullSaveOrder';
var UPDATE_CLIP_EVENT = 'updateClip';
var UPDATE_LIKES_EVENT = 'updateLikes';
var UPDATE_LIKE_EVENT = 'updateLike';
var UPDATE_FAVOURITES_EVENT = 'updateFavourites';
var UPDATE_FAVOURITE_EVENT = 'updateFavourite';
var GET_FAVOURITES_EVENT = 'getFavourites';
var UPDATE_SENTIMENTS_EVENT = 'updateSentiments';
var UPDATE_TRASH_EVENT = 'updateTrash';
// const PUSHED_CLIPS_EVENT = 'pushedClips';
var GET_CLIPS_EVENT = 'getClips';
var GET_CLIP_EVENT = 'getClip';
var TRANSLATE_EVENT = 'translateClip';
var GET_LANGUAGES_EVENT = 'getLanguages';
var GET_MICROSOFT_LANGUAGES_EVENT = 'getMicrosoftLanguages';
var FACET_ITEM_KEY_SEPARATOR = parameters.FACET_ITEM_KEY_SEPARATOR;
var ClipStore = /** @class */ (function (_super) {
    __extends(ClipStore, _super);
    function ClipStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this._registerToActions.bind(_this); });
        _this.updateDefaultTab = _this.updateDefaultTab.bind(_this);
        _this.lastScrollPosition = 0;
        return _this;
    }
    ClipStore.getClipsInClipsGroupsDictionary = function (clipsGroupsDictionary) {
        return clipsGroupsDictionary
            ? [].concat(Object.keys(clipsGroupsDictionary).reduce(function (previous, current) {
                var clipSelection = clipsGroupsDictionary[current];
                return previous
                    .concat(Array.isArray(clipSelection.clips) &&
                    clipSelection.clips.length > 0 &&
                    clipSelection.clips)
                    .concat(clipSelection.clipsGroupsDictionary &&
                    Object.keys(clipSelection.clipsGroupsDictionary).length > 0
                    ? ClipStore.getClipsInClipsGroupsDictionary(clipSelection.clipsGroupsDictionary)
                    : []);
            }, []))
            : [];
    };
    ClipStore.addOrder = function (clip, order) {
        if (!clip) {
            return;
        }
        if (!Array.isArray(clip.orders)) {
            clip.orders = [];
        }
        clip.orders.push(order);
    };
    ClipStore.setOrdersCount = function (clip, ordersCount) {
        if (!clip || !Number.isInteger(ordersCount)) {
            return;
        }
        clip.ordersCount = ordersCount;
    };
    ClipStore.setCodedClips = function (clipsIds, self) {
        if (!clipsIds || (clipsIds === null || clipsIds === void 0 ? void 0 : clipsIds.length) === 0) {
            return;
        }
        clipsIds.forEach(function (clipId) {
            var clip = self.getClipById(clipId);
            if (clip) {
                clip.coded = true;
            }
        });
    };
    ClipStore.setOriginalLanguage = function (clip, originalLanguage) {
        if (!clip) {
            return;
        }
        clip.originalLanguage = originalLanguage;
    };
    ClipStore.setCountry = function (clip, country) {
        if (!clip) {
            return;
        }
        clip.country = country;
    };
    ClipStore.setTranslations = function (clip, translations) {
        if (!clip) {
            return;
        }
        clip.translations = translations;
    };
    ClipStore.setFetched = function (clip, fetched) {
        if (!clip) {
            return;
        }
        clip.fetched = fetched;
    };
    ClipStore.setAuthor = function (clip, author) {
        if (!clip || (typeof author !== 'string' && clip.author)) {
            return;
        }
        clip.author = typeof author === 'string' ? author : '';
    };
    ClipStore.setCustomerMetrics = function (clip, customerMetrics) {
        if (!clip || !customerMetrics) {
            return;
        }
        clip.customerMetrics = customerMetrics;
    };
    ClipStore.setAve = function (clip, ave) {
        if (!clip || ave === undefined || isNaN(ave)) {
            return;
        }
        clip.ave = ave;
        if (clip.additionalInformations) {
            clip.additionalInformations.ave = clip.ave;
        }
    };
    ClipStore.setCoded = function (clip, coded) {
        if (!clip) {
            return;
        }
        clip.coded = coded;
    };
    ClipStore.setEditFullTextHistory = function (clip, editFullTextHistory) {
        if (!editFullTextHistory) {
            return;
        }
        if (Array.isArray(editFullTextHistory)) {
            clip.editFullTextHistory = editFullTextHistory[0];
        }
        else {
            clip.editFullTextHistory = editFullTextHistory;
        }
    };
    ClipStore.setAdditionalInformations = function (clip, infos) {
        var _a, _b;
        if (!clip || !infos) {
            return;
        }
        if (infos === null || infos === void 0 ? void 0 : infos.startTime) {
            var startTime = convertFromTimeStringToDateTime(infos.startTime);
            infos.startTime =
                (((_a = km === null || km === void 0 ? void 0 : km.getTimeZone) === null || _a === void 0 ? void 0 : _a.call(km)) &&
                    new Date(window
                        .moment(createDateAsUTC(new Date(startTime)))
                        .tz(km.getTimeZone())
                        .format('YYYY-MM-DD HH:mm:ss'))) ||
                    createDateAsUTC(new Date(startTime));
        }
        if (infos === null || infos === void 0 ? void 0 : infos.endTime) {
            var endTime = convertFromTimeStringToDateTime(infos.endTime);
            infos.endTime =
                (((_b = km === null || km === void 0 ? void 0 : km.getTimeZone) === null || _b === void 0 ? void 0 : _b.call(km)) &&
                    new Date(window
                        .moment(createDateAsUTC(new Date(endTime)))
                        .tz(km.getTimeZone())
                        .format('YYYY-MM-DD HH:mm:ss'))) ||
                    createDateAsUTC(new Date(endTime));
        }
        clip.additionalInformations = infos;
    };
    ClipStore.setRelatedArticles = function (clip, relatedArticles) {
        if (!clip) {
            return;
        }
        clip.relatedArticles = relatedArticles;
    };
    ClipStore.prototype.updateDefaultTab = function (key, value) {
        this.defaultTab[key] = value;
    };
    ClipStore.setSerializedHighlights = function (clip, serializedHighlights) {
        if (!clip) {
            return;
        }
        clip.serializedHighlights = serializedHighlights;
    };
    ClipStore.setClipSelections = function (clip, clipSelections) {
        if (!clip || (!clipSelections && clip.clipSelections)) {
            return;
        }
        clip.clipSelections = Array.isArray(clipSelections)
            ? clipSelections
                .map(function (_a) {
                var id = _a.id, name = _a.name, score = _a.score;
                return typeof id === 'string' &&
                    typeof name === 'string' && {
                    id: id,
                    name: name,
                    score: score,
                };
            })
                .filter(function (clipSelection) { return clipSelection; })
            : [];
    };
    ClipStore.setAReviewInfos = function (clip, aReviewsInfos) {
        if (!clip || (!aReviewsInfos && clip.aReviewsInfos)) {
            return;
        }
        clip.aReviewsInfos = Array.isArray(aReviewsInfos)
            ? aReviewsInfos
                .map(function (_a) {
                var name = _a.name, createdDate = _a.createdDate, paths = _a.paths;
                return typeof name === 'string' && {
                    name: name,
                    createdDate: createdDate,
                    paths: paths,
                };
            })
                .filter(function (aReviewsInfo) { return aReviewsInfo; })
            : [];
    };
    ClipStore.setDeliveredDate = function (clip, deliveredDate) {
        if (!clip || (!deliveredDate && clip.deliveredDate)) {
            return;
        }
        var date = new Date(deliveredDate);
        clip.deliveredDate = DateUtils.parseDate(date);
    };
    ClipStore.setDenyRequest = function (clip, denyRequest) {
        if (!clip || !denyRequest) {
            return;
        }
        var comments = denyRequest.comments, creationDate = denyRequest.creationDate, reply = denyRequest.reply, status = denyRequest.status;
        var date = typeof creationDate === 'string' ? new Date(creationDate) : null;
        clip.denyRequest =
            Object.prototype.toString.call(date) === '[object Date]' &&
                !isNaN(date.getTime()) &&
                typeof status === 'number'
                ? {
                    comments: typeof comments === 'string' ? comments : '',
                    creationDate: createDateAsUTC(date),
                    reply: typeof reply === 'string' ? reply : '',
                    status: status,
                }
                : null;
    };
    ClipStore.setFiles = function (clip, files) {
        if (!clip || (!files && clip.files))
            return;
        clip.files = Array.isArray(clip.files) ? clip.files : [];
        if (!Array.isArray(files))
            return;
        if (typeof files[0] === 'string' && files[0] === 'NewPDFUrl') {
            var pdfFile = clip.files.find(function (fileItem) { return fileItem.type === 'pdf'; });
            if (!pdfFile) {
                return;
            }
            pdfFile.url = null;
        }
        clip.files = files
            .concat(files
            .map(function (file) {
            var _a = file.height, height = _a === void 0 ? null : _a, mime = file.mime, name = file.name, type = file.type, url = file.url, _b = file.width, width = _b === void 0 ? null : _b;
            var isFileExist = clip.files.find(function (fileItem) { return fileItem.name === name; });
            return (typeof mime === 'string' &&
                typeof type === 'string' &&
                !isFileExist && {
                height: height,
                mime: mime,
                name: name,
                type: type,
                url: url,
                width: width,
            });
        })
            .filter(function (file) { return file; }))
            .filter(function (value, index, self) {
            return index ===
                self.findIndex(function (t) { return t.url === value.url && t.type === value.type; });
        });
    };
    ClipStore.setFullTextLength = function (clip, fullTextLength) {
        if (!clip || (!fullTextLength && clip.fullTextLength)) {
            return;
        }
        clip.fullTextLength =
            typeof fullTextLength === 'number' ? fullTextLength : 0;
    };
    ClipStore.setHeadlines = function (clip, headlines) {
        if (!clip || (!headlines && clip.headlines)) {
            return;
        }
        clip.headlines = Array.isArray(headlines)
            ? headlines.filter(function (headline) {
                var isoCode = headline.isoCode, original = headline.original, text = headline.text;
                var textElement = document.createElement('span');
                textElement.innerHTML = text;
                return (typeof isoCode === 'string' &&
                    typeof original === 'boolean' &&
                    (typeof text === 'string' || text === null) &&
                    Object.assign(headline, {
                        isoCode: isoCode.toLowerCase(),
                        // text: textElement.textContent,
                        text: text,
                    }));
            })
            : [];
        ClipStore.setTitle(clip);
    };
    ClipStore.setIsHumanSentiment = function (clip, isHumanSentiment) {
        if (!clip ||
            (!typeof isHumanSentiment === 'boolean' && clip.isHumanSentiment)) {
            return;
        }
        clip.isHumanSentiment =
            typeof isHumanSentiment === 'boolean' ? isHumanSentiment : false;
    };
    ClipStore.setIFrameAccess = function (clip, iFrameAccess) {
        if (!clip || (!iFrameAccess && clip.iFrameAccess)) {
            return;
        }
        clip.iFrameAccess = typeof iFrameAccess === 'number' ? iFrameAccess : 0;
    };
    ClipStore.setIsCopyrighted = function (clip, isCopyrighted) {
        if (!clip || (!isCopyrighted && clip.isCopyrighted)) {
            return;
        }
        clip.isCopyrighted =
            typeof isCopyrighted === 'boolean' ? isCopyrighted : false;
    };
    ClipStore.setIsExpirationDate = function (clip, isExpirationDate) {
        if (!clip || (!isExpirationDate && clip.isExpirationDate)) {
            return;
        }
        clip.isExpirationDate =
            typeof isExpirationDate === 'boolean' ? isExpirationDate : false;
    };
    ClipStore.setIsForbidden = function (clip, isForbidden) {
        if (!clip || (!isForbidden && clip.isForbidden)) {
            return;
        }
        clip.isForbidden = typeof isForbidden === 'boolean' ? isForbidden : false;
    };
    ClipStore.setKeywords = function (clip, keywords) {
        if (!clip || (!keywords && clip.keywords)) {
            return;
        }
        clip.keywords = Array.isArray(keywords)
            ? keywords.filter(function (keyword) { return typeof keyword === 'string'; })
            : [];
    };
    ClipStore.setMedium = function (clip, medium) {
        if (!clip || (!medium && clip.medium)) {
            return;
        }
        if (typeof medium === 'string') {
            clip.medium = medium;
        }
        else if (typeof medium === 'number') {
            clip.medium = convertMediumEnumtoMediumName(medium);
        }
        else {
            clip.medium = '';
        }
    };
    ClipStore.setNumberOfComments = function (clip, numberOfComments) {
        if (!clip || typeof numberOfComments !== 'number') {
            return;
        }
        clip.numberOfComments = numberOfComments >= 0 ? numberOfComments : 0;
    };
    ClipStore.setIsContentLink = function (clip, isContentLink) {
        if (!clip || (!isContentLink && clip.isContentLink)) {
            return;
        }
        clip.isContentLink = isContentLink;
    };
    ClipStore.setContentLinkUrl = function (clip, contentLinkUrl) {
        if (!clip || (!contentLinkUrl && clip.contentLinkUrl)) {
            return;
        }
        clip.contentLinkUrl = contentLinkUrl;
    };
    ClipStore.setCanCreateUnitPdf = function (clip, canCreateUnitPdf) {
        if (!clip || (!canCreateUnitPdf && clip.canCreateUnitPdf)) {
            return;
        }
        clip.canCreateUnitPdf = canCreateUnitPdf;
    };
    ClipStore.setNumberOfHighlights = function (clip, numberOfHighlights) {
        if (!clip || typeof numberOfHighlights !== 'number') {
            return;
        }
        clip.numberOfHighlights =
            typeof numberOfHighlights === 'number' ? numberOfHighlights : 0;
    };
    ClipStore.setNumberOfLikes = function (clip, numberOfLikes) {
        if (!clip || typeof numberOfLikes !== 'number') {
            return;
        }
        clip.numberOfLikes = typeof numberOfLikes === 'number' ? numberOfLikes : 0;
    };
    ClipStore.setIsFavourite = function (clip, isFavourite) {
        if (!clip || typeof isFavourite !== 'boolean') {
            return;
        }
        clip.isFavourite = typeof isFavourite === 'boolean' ? isFavourite : 0;
    };
    ClipStore.setOrders = function (clip, orders) {
        if (!clip || (!orders && clip.orders)) {
            return;
        }
        clip.orders = Array.isArray(orders)
            ? orders.map(function (order) {
                var _a;
                var comments = order.comments, creationDate = order.creationDate, items = order.items;
                var date = new Date(creationDate);
                var validItems = items.map(function (item) {
                    var count = item.count, isFullProgram = item.isFullProgram, type = item.type, typeName = item.typeName;
                    return (typeof count === 'number' &&
                        typeof isFullProgram === 'boolean' &&
                        typeof type === 'number' &&
                        typeof typeName === 'string' && {
                        count: count,
                        isFullProgram: isFullProgram,
                        type: type,
                        typeName: typeName,
                    });
                });
                return (typeof comments === 'string' &&
                    Object.prototype.toString.call(date) === '[object Date]' &&
                    !isNaN(date.getTime()) && {
                    comments: comments,
                    creationDate: (((_a = km === null || km === void 0 ? void 0 : km.getTimeZone) === null || _a === void 0 ? void 0 : _a.call(km)) &&
                        new Date(window
                            .moment(createDateAsUTC(date))
                            .tz(km.getTimeZone())
                            .format('YYYY-MM-DD HH:mm:ss'))) ||
                        createDateAsUTC(date),
                    items: validItems,
                });
            })
            : [];
    };
    ClipStore.setOts = function (clip, ots) {
        if (!clip || ots === undefined || isNaN(ots)) {
            return;
        }
        clip.ots = ots;
        if (clip.additionalInformations) {
            clip.additionalInformations.ots = clip.ots;
        }
    };
    ClipStore.setMediaImpactScore = function (clip, mediaImpactScore) {
        if (!clip || mediaImpactScore === undefined || isNaN(mediaImpactScore)) {
            return;
        }
        clip.mediaImpactScore = mediaImpactScore;
        if (clip.additionalInformations) {
            clip.additionalInformations.mediaImpactScore = clip.mediaImpactScore;
        }
    };
    ClipStore.setPage = function (clip, page) {
        if (!clip || page === undefined) {
            return;
        }
        clip.page = typeof page === 'string' ? page : '';
    };
    ClipStore.setProgram = function (clip, program) {
        if (!clip || (!program && clip.program)) {
            return;
        }
        clip.program = typeof program === 'string' ? program : '';
    };
    ClipStore.setPublicationDate = function (clip, publicationDate) {
        if (!clip || (!publicationDate && clip.publicationDate)) {
            return;
        }
        var date = new Date(publicationDate);
        clip.publicationDate = DateUtils.parseDate(date);
    };
    ClipStore.setSentiment = function (clip, sentiment) {
        if (!clip || (!sentiment && clip.sentiment)) {
            return;
        }
        var sentimentEnum = new Set(parameters.SENTIMENT_ENUM);
        clip.sentiment = sentimentEnum.has(sentiment) ? sentiment : 'notanalyzed';
    };
    ClipStore.setSocialShare = function (clip, socialShare) {
        if (!clip || (!socialShare && clip.socialShare)) {
            return;
        }
        clip.socialShare = typeof socialShare === 'number' ? socialShare : 0;
    };
    ClipStore.setSourceIsoCode = function (clip, sourceIsoCode) {
        if (!clip || (!sourceIsoCode && clip.sourceIsoCode)) {
            return;
        }
        clip.sourceIsoCode =
            typeof sourceIsoCode === 'string' ? sourceIsoCode.toLowerCase() : '';
    };
    ClipStore.setSourceName = function (clip, sourceName) {
        if (!clip || (!sourceName && clip.sourceName)) {
            return;
        }
        clip.sourceName = typeof sourceName === 'string' ? sourceName : '';
    };
    ClipStore.setSourceType = function (clip, sourceType) {
        if (!clip || (!sourceType && clip.sourceType)) {
            return;
        }
        clip.sourceType = typeof sourceType === 'string' ? sourceType : 0;
    };
    ClipStore.setSourceUrl = function (clip, sourceUrl) {
        if (!clip || (!sourceUrl && clip.sourceUrl)) {
            return;
        }
        clip.sourceUrl = typeof sourceUrl === 'string' ? sourceUrl : '';
    };
    ClipStore.setSummaries = function (clip, summaries) {
        if (!clip || (!summaries && clip.summaries)) {
            return;
        }
        clip.summaries = Array.isArray(summaries)
            ? summaries.filter(function (summary) {
                var isoCode = summary.isoCode, original = summary.original, text = summary.text;
                var textElement = document.createElement('span');
                textElement.innerHTML = text;
                return (typeof isoCode === 'string' &&
                    typeof original === 'boolean' &&
                    typeof text === 'string' &&
                    Object.assign(summary, {
                        isoCode: isoCode.toLowerCase(),
                        // text: textElement.textContent,
                        text: text,
                    }));
            })
            : [];
    };
    ClipStore.setText = function (clip, text) {
        if (!clip) {
            return;
        }
        var textElement = document.createElement('span');
        textElement.innerHTML = text;
        var textContentText = textElement.textContent;
        var cleanText = typeof textContentText === 'string' && textContentText !== 'undefined'
            ? textContentText
            : '';
        if (typeof clip.text === 'string' && clip.text.length < cleanText.length) {
            clip.text = cleanText;
        }
        else if (typeof clip.text !== 'string') {
            clip.text = cleanText;
        }
    };
    ClipStore.setPreviewText = function (clip, text) {
        if (!clip) {
            return;
        }
        clip.previewText = text;
    };
    ClipStore.setIsTeased = function (clip, isTeased) {
        if (!clip) {
            return;
        }
        clip.isTeased = isTeased;
    };
    ClipStore.setTextWithHtml = function (clip, textWithHtml) {
        if (!clip || (!textWithHtml && clip.textWithHtml)) {
            return;
        }
        clip.textWithHtml =
            typeof textWithHtml === 'string' ? DOMPurify.sanitize(textWithHtml) : '';
    };
    ClipStore.setTitle = function (clip, title) {
        var _a;
        if (!clip) {
            return;
        }
        var hasHeadlines = Array.isArray(clip.headlines) && clip.headlines.length > 0;
        var hasTranslations = hasHeadlines && clip.headlines.length > 1;
        var userHeadlineLanguage = hasTranslations &&
            clip.headlines.find(function (headlineItem) { return headlineItem.isoCode === getLang(); });
        if (userHeadlineLanguage) {
            clip.title = userHeadlineLanguage.text;
        }
        else {
            // clip.title = hasHeadlines ? clip.headlines[0].text : cleanTitle;
            clip.title = (_a = clip.headlines[0]) === null || _a === void 0 ? void 0 : _a.text;
        }
        var titleElement = document.createElement('span');
        titleElement.innerHTML = clip.title;
        var textContentTitle = titleElement.textContent;
        var cleanTitle = typeof textContentTitle === 'string' && textContentTitle !== 'undefined'
            ? textContentTitle
            : '';
        clip.cleanTitle = cleanTitle;
    };
    ClipStore.setClipTranslation = function (clip, translations, isoCode) {
        if (!clip || !translations) {
            return;
        }
        if (translations.length) {
            ClipStore.setHeadlines(clip, __spreadArray(__spreadArray([], clip.headlines, true), [
                { isAuto: true, isoCode: isoCode, original: false, text: translations[0] },
            ], false));
        }
        clip.translations = translations;
    };
    ClipStore.prototype.initializeState = function () {
        this.clips = [];
        this._defaultTab = [];
        this.openedClipId = '';
        this.pdfUrl = '';
        this._lastScrollPosition = 0;
        this._association = null;
    };
    ClipStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    ClipStore.prototype.getPdfUrl = function () {
        return this.pdfUrl;
    };
    ClipStore.prototype.setPdfUrl = function (url) {
        this.pdfUrl = url;
    };
    Object.defineProperty(ClipStore.prototype, "clips", {
        get: function () {
            return this._clips;
        },
        set: function (value) {
            this._clips = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClipStore.prototype, "association", {
        get: function () {
            return this._association;
        },
        set: function (value) {
            this._association = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClipStore.prototype, "lastScrollPosition", {
        get: function () {
            return this._lastScrollPosition;
        },
        set: function (value) {
            this._lastScrollPosition = value;
        },
        enumerable: false,
        configurable: true
    });
    ClipStore.prototype.getOpenedClipId = function () {
        return this.openedClipId;
    };
    ClipStore.prototype.setOpenedClipId = function (value) {
        this.openedClipId = value;
    };
    ClipStore.prototype.setLanguages = function (data) {
        this.languages = data;
    };
    ClipStore.prototype.setMicrosoftLanguages = function (data) {
        this.microsoftLanguages = data;
    };
    ClipStore.prototype.getLanguages = function () {
        return this.languages;
    };
    ClipStore.prototype.getMicrosoftLanguages = function () {
        return this.microsoftLanguages;
    };
    ClipStore.prototype.addClip = function (id, indexName) {
        var clip = this.getClipById(id);
        if (typeof id === 'string' && typeof indexName === 'string' && !clip) {
            var newClip = {
                id: id,
                indexName: indexName,
            };
            this.clips.push(newClip);
            return newClip;
        }
        return clip;
    };
    ClipStore.prototype.addClips = function (clips) {
        this.clips = __spreadArray(__spreadArray([], clips, true), this.clips, true);
        return clips.map(function (clip) { return clip.id; });
    };
    ClipStore.prototype.getClipById = function (id) {
        return this.clips.find(function (clip) { return clip.id === id; });
    };
    ClipStore.prototype.getClipsByIds = function (ids) {
        var clips = [];
        for (var index = 0; index < this.clips.length; index += 1) {
            if (ids.indexOf(this.clips[index].id) > -1) {
                clips.push({
                    id: this.clips[index].id,
                    indexName: this.clips[index].indexName,
                });
            }
        }
        return clips;
    };
    ClipStore.prototype.getClipClipSelectionsAssociation = function (ids) {
        if (ids === void 0) { ids = null; }
        var association = {};
        // if ids is passed it mean we sent only a selection of clips otherwise
        // we assume that all clips was selected and we loop throught all clipStore clips
        if (ids) {
            this.clips.forEach(function (clip) {
                if (ids.includes(clip.id) && clip.clipSelections.length > 0) {
                    clip.clipSelections.forEach(function (cs) {
                        if (!association[cs.id]) {
                            association[cs.id] = 1;
                        }
                        else {
                            association[cs.id] += 1;
                        }
                    });
                }
            });
        }
        else {
            this.clips.forEach(function (clip) {
                clip.clipSelections.forEach(function (cs) {
                    if (!association[cs.id]) {
                        association[cs.id] = 1;
                    }
                    else {
                        association[cs.id] += 1;
                    }
                });
            });
        }
        return association;
    };
    ClipStore.prototype.getAllClipIds = function () {
        return this.clips.map(function (clip) { return clip.id; });
    };
    ClipStore.prototype.getAllClips = function () {
        return this.clips;
    };
    ClipStore.prototype.getDocumentsByClipId = function (clipId) {
        var clip = this.getClipById(clipId);
        return clip.files.filter(function (file) { return DOCUMENT_TYPE_ENUM.includes(file.type); });
    };
    ClipStore.prototype.getFileByType = function (clipId, type) {
        var clip = this.getClipById(clipId);
        return (clip != null &&
            Array.isArray(clip.files) &&
            clip.files.find(function (file) { return file.type === type; }));
    };
    ClipStore.prototype.setFileUrlByType = function (id, type, url) {
        var clip = this.getClipById(id);
        var files = Array.isArray(clip.files) || [];
        var file = files && clip.files.find(function (fileItem) { return fileItem.type === type; });
        if (file && typeof url === 'string') {
            file.url = url;
        }
    };
    ClipStore.prototype.updateClipText = function (id, text) {
        var clip = this.getClipById(id);
        if (!clip || (!text && clip.text)) {
            return;
        }
        var textElement = document.createElement('span');
        textElement.innerHTML = text;
        clip.text = typeof text === 'string' ? textElement.textContent : '';
        clip.textWithHtml =
            typeof text === 'string' ? DOMPurify.sanitize(text) : '';
    };
    ClipStore.prototype.getHeadlineByIsoCode = function (clipId, isoCode, withoutHtml) {
        if (withoutHtml === void 0) { withoutHtml = false; }
        var clip = this.getClipById(clipId);
        var headline;
        if (clip) {
            headline = clip.headlines.find(function (headlineItem) { return headlineItem.isoCode === isoCode; });
            if (headline && withoutHtml) {
                var textElement = document.createElement('span');
                textElement.innerHTML = headline.text;
                return __assign(__assign({}, headline), { text: textElement.textContent });
            }
        }
        return headline;
    };
    ClipStore.prototype.getOriginalHeadline = function (clipId, withoutHtml) {
        if (withoutHtml === void 0) { withoutHtml = false; }
        var clip = this.getClipById(clipId);
        var headline;
        if (clip) {
            headline = clip.headlines.find(function (headlineItem) { return headlineItem.original; });
            if (headline && withoutHtml) {
                var textElement = document.createElement('span');
                textElement.innerHTML = headline.text;
                return __assign(__assign({}, headline), { text: textElement.textContent });
            }
        }
        return headline;
    };
    ClipStore.prototype.getSummaryByIsoCode = function (clipId, isoCode, withoutHtml) {
        if (withoutHtml === void 0) { withoutHtml = false; }
        var clip = this.getClipById(clipId);
        if (clip === null || clip === void 0 ? void 0 : clip.summaries) {
            var result = clip === null || clip === void 0 ? void 0 : clip.summaries.find(function (summaryItem) { return summaryItem.isoCode === isoCode; });
            if (withoutHtml && result) {
                var textElement = document.createElement('span');
                textElement.innerHTML = result.text;
                result.text = textElement.textContent;
            }
            return result;
        }
        return '';
    };
    ClipStore.prototype.getTextWithoutHtml = function (text) {
        var textElement = document.createElement('span');
        textElement.innerHTML = text;
        text = textElement.textContent;
        return text;
    };
    ClipStore.prototype.getOriginalIsoCodeByClipId = function (clipId) {
        var clip = this.getClipById(clipId);
        if (!clip) {
            return '';
        }
        var hasHeadlines = Boolean(clip.headlines.length);
        var originalHeadline = clip.headlines.find(function (_a) {
            var original = _a.original;
            return original;
        }) || clip.headlines[0];
        return hasHeadlines ? originalHeadline.isoCode : '';
    };
    Object.defineProperty(ClipStore.prototype, "defaultTab", {
        get: function () {
            return this._defaultTab;
        },
        set: function (defaultTab) {
            this._defaultTab = defaultTab;
        },
        enumerable: false,
        configurable: true
    });
    ClipStore.prototype.emitAddClip = function () {
        this.emit(ADD_CLIP_EVENT);
    };
    ClipStore.prototype.addAddClipListener = function (callback) {
        this.on(ADD_CLIP_EVENT, callback);
    };
    ClipStore.prototype.removeAddClipListener = function (callback) {
        this.removeListener(ADD_CLIP_EVENT, callback);
    };
    ClipStore.prototype.emitAddConsumption = function (type, isDownload, clipId, context) {
        this.emit(ADD_CONSUMPTION_EVENT, type, isDownload, clipId, context);
    };
    ClipStore.prototype.addAddConsumptionListener = function (callback) {
        this.on(ADD_CONSUMPTION_EVENT, callback);
    };
    ClipStore.prototype.removeAddConsumptionListener = function (callback) {
        this.removeListener(ADD_CONSUMPTION_EVENT, callback);
    };
    ClipStore.prototype.emitEvaluateConsumption = function (type, isDownload, clipId, context) {
        this.emit(ADD_EVALUATE_CONSUMPTION_EVENT, type, isDownload, clipId, context);
    };
    ClipStore.prototype.addGetClipsListener = function (callback) {
        this.on(GET_CLIPS_EVENT, callback);
    };
    ClipStore.prototype.removeGetClipsListener = function (callback) {
        this.removeListener(GET_CLIPS_EVENT, callback);
    };
    ClipStore.prototype.emitGetClips = function (clips) {
        this.emit(GET_CLIPS_EVENT, clips);
    };
    ClipStore.prototype.addGetClipListener = function (callback) {
        this.on(GET_CLIP_EVENT, callback);
    };
    ClipStore.prototype.removeGetClipListener = function (callback) {
        this.removeListener(GET_CLIP_EVENT, callback);
    };
    ClipStore.prototype.emitGetClip = function (clipId) {
        this.emit(GET_CLIP_EVENT, clipId);
    };
    ClipStore.prototype.addEvaluateConsumptionListener = function (callback) {
        this.on(ADD_EVALUATE_CONSUMPTION_EVENT, callback);
    };
    ClipStore.prototype.removeEvaluateConsumptionListener = function (callback) {
        this.removeListener(ADD_EVALUATE_CONSUMPTION_EVENT, callback);
    };
    ClipStore.prototype.emitDenyArticle = function (failedIds) {
        this.emit(DENY_ARTICLE_EVENT, failedIds);
    };
    ClipStore.prototype.addDenyArticleListener = function (callback) {
        this.on(DENY_ARTICLE_EVENT, callback);
    };
    ClipStore.prototype.removeDenyArticleListener = function (callback) {
        this.removeListener(DENY_ARTICLE_EVENT, callback);
    };
    ClipStore.prototype.emitGetAllClips = function (skip) {
        this.emit(GET_ALL_CLIPS_EVENT, skip);
    };
    ClipStore.prototype.addGetAllClipsListener = function (callback) {
        this.on(GET_ALL_CLIPS_EVENT, callback);
    };
    ClipStore.prototype.removeGetAllClipsListener = function (callback) {
        this.removeListener(GET_ALL_CLIPS_EVENT, callback);
    };
    // emit consumption
    ClipStore.prototype.emitConsumption = function (id, type) {
        this.emit(CONSUMPTION_EVENT, id, type);
    };
    ClipStore.prototype.addConsumptionListener = function (callback) {
        this.on(CONSUMPTION_EVENT, callback);
    };
    ClipStore.prototype.removeConsumptionListener = function (callback) {
        this.removeListener(CONSUMPTION_EVENT, callback);
    };
    //
    ClipStore.prototype.emitGetClipSelectionsByClipIds = function (type) {
        this.emit(GET_CLIP_SELECTIONS_BY_CLIP_IDS_EVENT, type);
    };
    ClipStore.prototype.addGetClipSelectionsByClipIdsListener = function (callback) {
        this.on(GET_CLIP_SELECTIONS_BY_CLIP_IDS_EVENT, callback);
    };
    ClipStore.prototype.removeGetClipSelectionsByClipIdsListener = function (callback) {
        this.removeListener(GET_CLIP_SELECTIONS_BY_CLIP_IDS_EVENT, callback);
    };
    ClipStore.prototype.emitGetNextClips = function (clipIds, facetName) {
        this.emit(GET_NEXT_CLIPS_EVENT, clipIds, facetName);
    };
    ClipStore.prototype.addGetNextClipsListener = function (callback) {
        this.on(GET_NEXT_CLIPS_EVENT, callback);
    };
    ClipStore.prototype.removeGetNextClipsListener = function (callback) {
        this.removeListener(GET_NEXT_CLIPS_EVENT, callback);
    };
    ClipStore.prototype.emitSuccessfullCategorize = function () {
        this.emit(SUCCESSFULL_CATEGORIZE_EVENT);
    };
    ClipStore.prototype.addSuccessfullCategorizeListener = function (callback) {
        this.on(SUCCESSFULL_CATEGORIZE_EVENT, callback);
    };
    ClipStore.prototype.removeSuccessfullCategorizeListener = function (callback) {
        this.removeListener(SUCCESSFULL_CATEGORIZE_EVENT, callback);
    };
    ClipStore.prototype.emitSuccessfullUnitPDF = function (data) {
        this.emit(SUCCESSFULL_UNIT_PDF_EVENT, data);
    };
    ClipStore.prototype.addSuccessfullUnitPDFListener = function (callback) {
        this.on(SUCCESSFULL_UNIT_PDF_EVENT, callback);
    };
    ClipStore.prototype.removeSuccessfullUnitPDFListener = function (callback) {
        this.removeListener(SUCCESSFULL_UNIT_PDF_EVENT, callback);
    };
    ClipStore.prototype.emitGetFavourites = function (response) {
        this.emit(GET_FAVOURITES_EVENT, response);
    };
    ClipStore.prototype.addGetFavouritesListener = function (callback) {
        this.on(GET_FAVOURITES_EVENT, callback);
    };
    ClipStore.prototype.removeGetFavouritesListener = function (callback) {
        this.removeListener(GET_FAVOURITES_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateFavourites = function (response) {
        this.emit(UPDATE_FAVOURITES_EVENT, response);
    };
    ClipStore.prototype.addUpdateFavouritesListener = function (callback) {
        this.on(UPDATE_FAVOURITES_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateFavouritesListener = function (callback) {
        this.removeListener(UPDATE_FAVOURITES_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateFavourite = function (response) {
        this.emit(UPDATE_FAVOURITE_EVENT, response);
    };
    ClipStore.prototype.addUpdateFavouriteListener = function (callback) {
        this.on(UPDATE_FAVOURITE_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateFavouriteListener = function (callback) {
        this.removeListener(UPDATE_FAVOURITE_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateLike = function (response) {
        this.emit(UPDATE_LIKE_EVENT, response);
    };
    ClipStore.prototype.addUpdateLikeListener = function (callback) {
        this.on(UPDATE_LIKE_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateLikeListener = function (callback) {
        this.removeListener(UPDATE_LIKE_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateLikes = function (response) {
        this.emit(UPDATE_LIKES_EVENT, response);
    };
    ClipStore.prototype.addUpdateLikesListener = function (callback) {
        this.on(UPDATE_LIKES_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateLikesListener = function (callback) {
        this.removeListener(UPDATE_LIKES_EVENT, callback);
    };
    ClipStore.prototype.emitSuccessfullSaveOrder = function () {
        this.emit(SUCCESSFULL_SAVE_ORDER_EVENT);
    };
    ClipStore.prototype.addSuccessfullSaveOrderListener = function (callback) {
        this.on(SUCCESSFULL_SAVE_ORDER_EVENT, callback);
    };
    ClipStore.prototype.removeSuccessfullSaveOrderListener = function (callback) {
        this.removeListener(SUCCESSFULL_SAVE_ORDER_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateSentiments = function (type) {
        this.emit(UPDATE_SENTIMENTS_EVENT, type);
    };
    ClipStore.prototype.addUpdateSentimentsListener = function (callback) {
        this.on(UPDATE_SENTIMENTS_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateSentimentsListener = function (callback) {
        this.removeListener(UPDATE_SENTIMENTS_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateClip = function (clip) {
        this.emit(UPDATE_CLIP_EVENT, clip);
    };
    ClipStore.prototype.addUpdateClipListener = function (callback) {
        this.on(UPDATE_CLIP_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateClipListener = function (callback) {
        this.removeListener(UPDATE_CLIP_EVENT, callback);
    };
    ClipStore.prototype.emitTranslation = function (clipIds) {
        this.emit(TRANSLATE_EVENT, clipIds);
    };
    ClipStore.prototype.addTranslationListener = function (callback) {
        this.on(TRANSLATE_EVENT, callback);
    };
    ClipStore.prototype.removeTranslationListener = function (callback) {
        this.removeListener(TRANSLATE_EVENT, callback);
    };
    ClipStore.prototype.emitGetLanguagesListener = function () {
        this.emit(GET_LANGUAGES_EVENT);
    };
    ClipStore.prototype.addGetLanguagesListener = function (callback) {
        this.on(GET_LANGUAGES_EVENT, callback);
    };
    ClipStore.prototype.removeGetLanguagesListener = function (callback) {
        this.removeListener(GET_LANGUAGES_EVENT, callback);
    };
    ClipStore.prototype.emitGetMicrosoftLanguagesListener = function () {
        this.emit(GET_MICROSOFT_LANGUAGES_EVENT);
    };
    ClipStore.prototype.addGetMicrosoftLanguagesListener = function (callback) {
        this.on(GET_MICROSOFT_LANGUAGES_EVENT, callback);
    };
    ClipStore.prototype.removeGetMicrosoftLanguagesListener = function (callback) {
        this.removeListener(GET_MICROSOFT_LANGUAGES_EVENT, callback);
    };
    ClipStore.prototype.emitUpdateTrash = function (clipIds) {
        this.emit(UPDATE_TRASH_EVENT, clipIds);
    };
    ClipStore.prototype.addUpdateTrashListener = function (callback) {
        this.on(UPDATE_TRASH_EVENT, callback);
    };
    ClipStore.prototype.removeUpdateTrashListener = function (callback) {
        this.removeListener(UPDATE_TRASH_EVENT, callback);
    };
    ClipStore.prototype.emitGetRelatedArticles = function (numberOfClips) {
        this.emit(GET_RELATED_ARTICLES_EVENT, numberOfClips);
    };
    ClipStore.prototype.addGetRelatedArticlesListener = function (callback) {
        this.on(GET_RELATED_ARTICLES_EVENT, callback);
    };
    ClipStore.prototype.removeGetRelatedArticlesListener = function (callback) {
        this.removeListener(GET_RELATED_ARTICLES_EVENT, callback);
    };
    ClipStore.prototype._registerToActions = function (payload) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.ADD_CLIP_RESPONSE:
                if (action.json) {
                    var _f = action.json, _g = _f.data, id = _g.id, indexName = _g.indexName, _h = _f.model.customerClip, author = _h.author.name, ave = _h.ave, files = _h.files, clipSelections = _h.clipSelections, deliveredDate = _h.deliveredDate, fullText = _h.fullText, headlines = _h.headlines, isTeased = _h.isTeased, keywords = _h.keywords, ots = _h.ots, publicationDate = _h.publicationDate, sentiment = _h.sentiment.level, _j = _h.source, sourceName = _j.name, medium = _j.type, summaries = _h.summaries, url = _h.url;
                    var clip = this.addClip(id, indexName);
                    var originalHeadline = headlines.find(function (_a) {
                        var original = _a.original;
                        return original;
                    });
                    var title = originalHeadline === null || originalHeadline === void 0 ? void 0 : originalHeadline.title;
                    ClipStore.setAuthor(clip, author);
                    ClipStore.setAve(clip, ave);
                    ClipStore.setFiles(clip, files);
                    ClipStore.setClipSelections(clip, clipSelections);
                    ClipStore.setDeliveredDate(clip, DateUtils.convertFromTimeStringToDateTime(deliveredDate));
                    ClipStore.setHeadlines(clip, headlines);
                    ClipStore.setIsTeased(clip, isTeased);
                    ClipStore.setKeywords(clip, keywords);
                    ClipStore.setMedium(clip, medium.toLowerCase());
                    ClipStore.setOts(clip, ots);
                    ClipStore.setPublicationDate(clip, DateUtils.convertFromTimeStringToDateTime(publicationDate));
                    ClipStore.setSentiment(clip, sentiment);
                    ClipStore.setSourceName(clip, sourceName);
                    ClipStore.setSourceUrl(clip, url);
                    ClipStore.setSummaries(clip, summaries);
                    ClipStore.setText(clip, fullText);
                    ClipStore.setTitle(clip, title);
                    this.emitAddClip();
                }
                else {
                    this.emitError('ADD_CLIP', action.errors.error);
                }
                break;
            case ActionTypes.ADD_COMMENT_RESPONSE:
                if (action.json) {
                    var id = action.json.model.id;
                    var clip = this.getClipById(id);
                    var numberOfComments = clip.numberOfComments;
                    ClipStore.setNumberOfComments(clip, numberOfComments + 1);
                    this.emitChange([id]);
                }
                break;
            case ActionTypes.ADD_CONSUMPTION_RESPONSE:
                if (action.json) {
                    var _k = action.json, data = _k.data, message = _k.message, _l = _k.model, id = _l.id, type = _l.type, isDownload = _l.isDownload, indexName = _l.indexName, context = _l.context;
                    var file = this.getFileByType(id, type);
                    if ((message === 'IsTeased' && data === null) ||
                        (!file && data !== null)) {
                        this.emitAddConsumption(data, message, indexName, id);
                    }
                    if (typeof data === 'string' && file && id) {
                        file.url = data;
                        this.setPdfUrl(data);
                        this.emitAddConsumption(type, isDownload, id, context);
                    }
                }
                else {
                    this.emitError('ADD_CONSUMPTION', action);
                }
                break;
            case ActionTypes.ADD_EVALUATE_CONSUMPTION_RESPONSE:
                if (action.json) {
                    var _m = action.json, data = _m.data, _o = _m.model, id = _o.id, type = _o.type, isDownload = _o.isDownload, context = _o.context;
                    var file = this.getFileByType(id, type);
                    if (typeof data === 'string' && file) {
                        file.url = data;
                        this.emitEvaluateConsumption(type, isDownload, id, context);
                        this.emitChange([id]);
                    }
                }
                else {
                    this.emitError('EVALUATE_CONSUMPTION', action);
                }
                break;
            case ActionTypes.ADD_HIGHLIGHT_RESPONSE:
                if (action.json) {
                    var id = action.json.model.id;
                    var clip = this.getClipById(id);
                    var numberOfHighlights = clip.numberOfHighlights;
                    ClipStore.setNumberOfHighlights(clip, numberOfHighlights + 1);
                    this.emitChange([id]);
                }
                break;
            case ActionTypes.CATEGORIZE_CLIP_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    if (Array.isArray(data)) {
                        data.forEach(function (clipsWithClipSelectionList) {
                            var clipSelections = clipsWithClipSelectionList.clipSelections, id = clipsWithClipSelectionList.id, indexName = clipsWithClipSelectionList.indexName;
                            var clip = _this.addClip(id, indexName);
                            ClipStore.setClipSelections(clip, clipSelections);
                        });
                        this.emitChange(data.map(function (_a) {
                            var id = _a.id;
                            return id;
                        }));
                    }
                    this.emitSuccessfullCategorize();
                }
                else {
                    this.emitError('CATEGORIZE_CLIP');
                }
                break;
            case ActionTypes.CREATE_UNIT_PDF_RESPONSE:
                break;
            case ActionTypes.CREATE_CLIP_ORDER_RESPONSE:
                if (action.json) {
                    var _p = action.json, creationDate = _p.data.creationDate, _q = _p.model, clipId = _q.clipId, clipOrderItemObject = _q.clipOrderItemObject;
                    var clip = this.getClipById(clipId);
                    var date = typeof creationDate === 'string' && new Date(creationDate);
                    if (Object.prototype.toString.call(date) === '[object Date]' &&
                        !isNaN(date.getTime())) {
                        clipOrderItemObject.creationDate =
                            (((_a = km === null || km === void 0 ? void 0 : km.getTimeZone) === null || _a === void 0 ? void 0 : _a.call(km)) &&
                                new Date(window
                                    .moment(createDateAsUTC(date))
                                    .tz(km.getTimeZone())
                                    .format('YYYY-MM-DD HH:mm:ss'))) ||
                                createDateAsUTC(date);
                        ClipStore.addOrder(clip, clipOrderItemObject);
                        this.emitChange([clipId]);
                        this.emitSuccessfullSaveOrder();
                    }
                    else {
                        this.emitError('CREATE_CLIP_ORDER');
                    }
                }
                else {
                    this.emitError('CREATE_CLIP_ORDER');
                }
                break;
            case ActionTypes.DELETE_COMMENT_RESPONSE:
                if (action.json) {
                    var id = action.json.model.id;
                    var clip = this.getClipById(id);
                    var numberOfComments = clip.numberOfComments;
                    ClipStore.setNumberOfComments(clip, numberOfComments - 1);
                    this.emitChange([id]);
                }
                break;
            case ActionTypes.DELETE_HIGHLIGHT_RESPONSE:
                if (action.json) {
                    var id = action.json.model.id;
                    var clip = this.getClipById(id);
                    var numberOfHighlights = clip.numberOfHighlights;
                    ClipStore.setNumberOfHighlights(clip, numberOfHighlights - 1);
                    this.emitChange([id]);
                }
                break;
            case ActionTypes.GET_ALL_CLIPS_RESPONSE:
                if (action.json && Array.isArray(action.json.data)) {
                    var data = action.json.data;
                    data.forEach(function (_a) {
                        var ave = _a.ave, clipSelections = _a.clipSelections, deliveredDate = _a.deliveredDate, files = _a.files, fullTextLength = _a.fullTextLength, headlines = _a.headlines, id = _a.id, indexName = _a.indexName, isHumanSentiment = _a.isHumanSentiment, isTeased = _a.isTeased, medium = _a.medium, numberOfComments = _a.numberOfComments, numberOfHighlights = _a.numberOfHighlights, numberOfLikes = _a.numberOfLikes, ots = _a.ots, program = _a.program, publicationDate = _a.publicationDate, sentiment = _a.sentiment, socialShare = _a.socialShare, sourceIsoCode = _a.sourceIsoCode, sourceName = _a.sourceName, text = _a.text, isContentLink = _a.isContentLink, denyRequest = _a.denyRequest, mediaImpactScore = _a.mediaImpactScore;
                        var clip = _this.addClip(id, indexName);
                        ClipStore.setAve(clip, ave);
                        ClipStore.setClipSelections(clip, clipSelections);
                        ClipStore.setDeliveredDate(clip, deliveredDate);
                        ClipStore.setFiles(clip, files);
                        ClipStore.setFullTextLength(clip, fullTextLength);
                        ClipStore.setHeadlines(clip, headlines);
                        ClipStore.setIsHumanSentiment(clip, isHumanSentiment);
                        ClipStore.setIsTeased(clip, isTeased);
                        ClipStore.setMedium(clip, medium);
                        ClipStore.setNumberOfComments(clip, numberOfComments);
                        ClipStore.setNumberOfHighlights(clip, numberOfHighlights);
                        ClipStore.setNumberOfLikes(clip, numberOfLikes);
                        ClipStore.setOts(clip, ots);
                        ClipStore.setProgram(clip, program);
                        ClipStore.setPublicationDate(clip, publicationDate);
                        ClipStore.setSentiment(clip, sentiment);
                        ClipStore.setSocialShare(clip, socialShare);
                        ClipStore.setSourceIsoCode(clip, sourceIsoCode);
                        ClipStore.setSourceName(clip, sourceName);
                        ClipStore.setText(clip, text);
                        ClipStore.setTextWithHtml(clip, text);
                        ClipStore.setIsContentLink(clip, isContentLink);
                        ClipStore.setDenyRequest(clip, denyRequest);
                        ClipStore.setMediaImpactScore(clip, mediaImpactScore);
                    });
                    this.emitGetAllClips(data.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                }
                else {
                    this.emitError('GET_ALL_CLIPS');
                }
                break;
            case ActionTypes.DENY_ARTICLE_RESPONSE:
                if (action.json) {
                    var ids_1 = [];
                    var failedIds_1 = [];
                    action.json.data.forEach(function (_a) {
                        var id = _a.id, customerCode = _a.customerCode, restParams = __rest(_a, ["id", "customerCode"]);
                        if (customerCode !== 200) {
                            failedIds_1.push(Id);
                        }
                        else {
                            var clip = _this.getClipById(id);
                            ids_1.push(id);
                            ClipStore.setDenyRequest(clip, restParams);
                        }
                    }, []);
                    this.emitChange(ids_1);
                    this.emitDenyArticle(failedIds_1);
                }
                else {
                    this.emitError('DENY_ARTICLE', action.errors);
                }
                break;
            case ActionTypes.GET_CLIP_RESPONSE:
                if (action.json) {
                    var clipId = void 0;
                    var clip = null;
                    var _r = action.json.data, additionalInformations = _r.additionalInformations, author = _r.author, clipSelections = _r.clipSelections, comments = _r.comments, deliveredDate = _r.deliveredDate, denyRequest = _r.denyRequest, files = _r.files, fullTextLength = _r.fullTextLength, headlines = _r.headlines, id = _r.id, iFrameAccess = _r.iFrameAccess, indexName = _r.indexName, isCopyrighted = _r.isCopyrighted, isExpirationDate = _r.isExpirationDate, isForbidden = _r.isForbidden, isTeased = _r.isTeased, keywords = _r.keywords, medium = _r.medium, numberOfLikes = _r.numberOfLikes, orders = _r.orders, publicationDate = _r.publicationDate, program = _r.program, sourceName = _r.sourceName, sourceType = _r.sourceType, sourceUrl = _r.sourceUrl, summaries = _r.summaries, text = _r.text, title = _r.title, isContentLink = _r.isContentLink, canCreateUnitPdf = _r.canCreateUnitPdf, contentLinkUrl = _r.contentLinkUrl, translations = _r.translations, originalLanguage = _r.originalLanguage, customerMetrics = _r.customerMetrics, editFullTextHistory = _r.editFullTextHistory;
                    if (!id || !indexName) {
                        clipId = action.json.model.id;
                        var clipIndex = action.json.model.indexName;
                        clip = this.addClip(clipId, clipIndex);
                    }
                    else {
                        clip = this.addClip(id, indexName);
                    }
                    // this.setOpenedClipId(clip.id);
                    ClipStore.setAdditionalInformations(clip, additionalInformations);
                    ClipStore.setAuthor(clip, author);
                    ClipStore.setCustomerMetrics(clip, customerMetrics);
                    ClipStore.setClipSelections(clip, clipSelections);
                    ClipStore.setDeliveredDate(clip, deliveredDate);
                    ClipStore.setDenyRequest(clip, denyRequest);
                    ClipStore.setFiles(clip, files);
                    ClipStore.setFullTextLength(clip, fullTextLength);
                    ClipStore.setHeadlines(clip, headlines);
                    ClipStore.setIsTeased(clip, isTeased);
                    ClipStore.setIFrameAccess(clip, iFrameAccess);
                    ClipStore.setIsCopyrighted(clip, isCopyrighted);
                    ClipStore.setIsExpirationDate(clip, isExpirationDate);
                    ClipStore.setIsForbidden(clip, isForbidden);
                    ClipStore.setKeywords(clip, keywords);
                    ClipStore.setMedium(clip, medium);
                    ClipStore.setProgram(clip, program);
                    ClipStore.setIsContentLink(clip, isContentLink);
                    ClipStore.setContentLinkUrl(clip, contentLinkUrl);
                    ClipStore.setCanCreateUnitPdf(clip, canCreateUnitPdf);
                    ClipStore.setEditFullTextHistory(clip, editFullTextHistory);
                    if (Array.isArray(comments)) {
                        ClipStore.setNumberOfComments(clip, comments.length);
                    }
                    ClipStore.setNumberOfLikes(clip, numberOfLikes);
                    ClipStore.setOrders(clip, orders);
                    ClipStore.setPublicationDate(clip, publicationDate);
                    ClipStore.setSourceName(clip, sourceName);
                    ClipStore.setSourceType(clip, sourceType);
                    ClipStore.setSourceUrl(clip, sourceUrl);
                    ClipStore.setSummaries(clip, summaries);
                    ClipStore.setText(clip, text);
                    ClipStore.setTextWithHtml(clip, text);
                    ClipStore.setTitle(clip, title);
                    ClipStore.setFetched(clip, true);
                    ClipStore.setTranslations(clip, translations);
                    ClipStore.setOriginalLanguage(clip, originalLanguage);
                    this.initialized = true;
                    this.emitGetClip(id || clipId);
                    this.emitChange([id || clipId].filter(function (c) { return c; }));
                }
                else {
                    this.emitError('GET_CLIP');
                }
                break;
            case ActionTypes.GET_CLIPS_REQUEST:
                this.emitLoading(true);
                break;
            case ActionTypes.GET_CLIPS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var clips = Array.isArray(data.data)
                        ? data.data
                        : ClipStore.getClipsInClipsGroupsDictionary(data.data);
                    this.defaultTab = data.defaultTab;
                    clips
                        .filter(function (clip) {
                        var findId = _this.clips.findIndex(function (elm) { return elm.id === clip.id; });
                        if (findId > -1) {
                            var clipObj = _this.getClipById(clip.id);
                            return Boolean(!clipObj.fetched);
                        }
                        return true;
                    })
                        .forEach(function (_a) {
                        var author = _a.author, ave = _a.ave, clipSelections = _a.clipSelections, coded = _a.coded, deliveredDate = _a.deliveredDate, denyRequest = _a.denyRequest, files = _a.files, fullTextLength = _a.fullTextLength, headlines = _a.headlines, id = _a.id, indexName = _a.indexName, isHumanSentiment = _a.isHumanSentiment, isTeased = _a.isTeased, keywords = _a.keywords, medium = _a.medium, numberOfComments = _a.numberOfComments, numberOfHighlights = _a.numberOfHighlights, numberOfLikes = _a.numberOfLikes, ordersCount = _a.ordersCount, ots = _a.ots, page = _a.page, program = _a.program, publicationDate = _a.publicationDate, sentiment = _a.sentiment, socialShare = _a.socialShare, sourceIsoCode = _a.sourceIsoCode, sourceName = _a.sourceName, sourceType = _a.sourceType, sourceUrl = _a.sourceUrl, text = _a.text, isFavourite = _a.isFavourite, isContentLink = _a.isContentLink, translations = _a.translations, originalLanguage = _a.originalLanguage, aReviewsInfos = _a.aReviewsInfos, country = _a.country, mediaImpactScore = _a.mediaImpactScore;
                        var clip = _this.addClip(id, indexName);
                        ClipStore.setAReviewInfos(clip, aReviewsInfos);
                        ClipStore.setAuthor(clip, author);
                        ClipStore.setAve(clip, ave);
                        ClipStore.setClipSelections(clip, clipSelections);
                        ClipStore.setCoded(clip, coded);
                        ClipStore.setDeliveredDate(clip, deliveredDate);
                        ClipStore.setDenyRequest(clip, denyRequest);
                        ClipStore.setFiles(clip, files);
                        ClipStore.setFullTextLength(clip, fullTextLength);
                        ClipStore.setHeadlines(clip, headlines);
                        ClipStore.setIsHumanSentiment(clip, isHumanSentiment);
                        ClipStore.setIsTeased(clip, isTeased);
                        ClipStore.setKeywords(clip, keywords);
                        ClipStore.setMedium(clip, medium);
                        ClipStore.setNumberOfComments(clip, numberOfComments);
                        ClipStore.setNumberOfHighlights(clip, numberOfHighlights);
                        ClipStore.setNumberOfLikes(clip, numberOfLikes);
                        ClipStore.setOts(clip, ots);
                        ClipStore.setOrdersCount(clip, ordersCount);
                        ClipStore.setProgram(clip, program);
                        ClipStore.setPublicationDate(clip, publicationDate);
                        ClipStore.setPage(clip, page);
                        ClipStore.setSentiment(clip, sentiment);
                        ClipStore.setSocialShare(clip, socialShare);
                        ClipStore.setSourceIsoCode(clip, sourceIsoCode);
                        ClipStore.setSourceName(clip, sourceName);
                        ClipStore.setSourceType(clip, sourceType);
                        ClipStore.setSourceUrl(clip, sourceUrl);
                        ClipStore.setPreviewText(clip, text);
                        ClipStore.setIsFavourite(clip, isFavourite);
                        ClipStore.setIsContentLink(clip, isContentLink);
                        ClipStore.setTranslations(clip, translations);
                        ClipStore.setOriginalLanguage(clip, originalLanguage);
                        ClipStore.setCountry(clip, country);
                        ClipStore.setMediaImpactScore(clip, mediaImpactScore);
                    });
                    this.initialized = true;
                    this.emitChange(data.data);
                    this.emitGetClips(data.data);
                }
                else {
                    this.emitError('GET_CLIPS');
                }
                break;
            case ActionTypes.SET_CLIP_SELECTED_TAB_RESPONSE:
                if (action.json && !action.json.model.consumptionDone) {
                    var content = action.json.data;
                    var _s = action.json.model, id = _s.id, type = _s.type;
                    if (type === 'pdf') {
                        this.setFileUrlByType(id, type, content);
                    }
                    else if (type === 'text') {
                        this.updateClipText(id, content);
                    }
                    if (content) {
                        this.emitConsumption(action.json.model.id, action.json.model.type);
                    }
                }
                else {
                    this.emitError();
                }
                break;
            case ActionTypes.GET_CLIP_SELECTIONS_BY_CLIP_IDS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    if (Array.isArray(data)) {
                        data.forEach(function (clipsWithClipSelectionList) {
                            var clipSelections = clipsWithClipSelectionList.clipSelections, id = clipsWithClipSelectionList.id, indexName = clipsWithClipSelectionList.indexName;
                            var clip = _this.addClip(id, indexName);
                            ClipStore.setClipSelections(clip, clipSelections);
                        });
                    }
                    this.emitChange(data.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                    this.emitGetClipSelectionsByClipIds();
                }
                else {
                    this.emitError('GET_CLIP_SELECTIONS_BY_CLIP_IDS');
                }
                break;
            case ActionTypes.GET_MEDIA_CLIP_RESPONSE: {
                if (action.json) {
                    var _t = action.json, _u = _t.data, url = _u.url, urlType = _u.urlType, id = _t.model.id;
                    this.setFileUrlByType(id, urlType, url);
                    this.emitChange([id]);
                }
                else {
                    this.emitError('GET_MEDIA_CLIP');
                }
                break;
            }
            case ActionTypes.GET_MEDIA_CLIP_NEWS_DIGEST_RESPONSE: {
                if (action.json) {
                    var _v = action.json, _w = _v.data, url = _w.url, urlType = _w.urlType, id = _v.model.id;
                    this.setFileUrlByType(id, urlType, url);
                    this.emitChange([id]);
                }
                else {
                    this.emitError('GET_MEDIA_CLIP');
                }
                break;
            }
            case ActionTypes.GET_FULLTEXT_CLIP_RESPONSE: {
                if (action.json) {
                    var _x = action.json, data = _x.data, id = _x.model.id;
                    var clip = this.getClipById(id);
                    ClipStore.setText(clip, data);
                    this.emitChange([id]);
                }
                else {
                    this.emitError('GET_FULLTEXT_CLIP');
                }
                break;
            }
            case ActionTypes.GET_NEXT_CLIPS_RESPONSE:
                if (action.json && Array.isArray(action.json.data)) {
                    var _y = action.json, data = _y.data, model_1 = _y.model;
                    data.forEach(function (_a) {
                        var ave = _a.ave, clipSelections = _a.clipSelections, coded = _a.coded, deliveredDate = _a.deliveredDate, files = _a.files, fullTextLength = _a.fullTextLength, headlines = _a.headlines, id = _a.id, indexName = _a.indexName, isHumanSentiment = _a.isHumanSentiment, isTeased = _a.isTeased, keywords = _a.keywords, numberOfLikes = _a.numberOfLikes, medium = _a.medium, numberOfComments = _a.numberOfComments, numberOfHighlights = _a.numberOfHighlights, ots = _a.ots, program = _a.program, page = _a.page, publicationDate = _a.publicationDate, sentiment = _a.sentiment, socialShare = _a.socialShare, sourceIsoCode = _a.sourceIsoCode, sourceName = _a.sourceName, summaries = _a.summaries, text = _a.text, mediaImpactScore = _a.mediaImpactScore;
                        var clip = _this.addClip(id, indexName);
                        ClipStore.setAve(clip, ave);
                        ClipStore.setClipSelections(clip, clipSelections);
                        ClipStore.setDeliveredDate(clip, deliveredDate);
                        ClipStore.setFiles(clip, files);
                        ClipStore.setFullTextLength(clip, fullTextLength);
                        ClipStore.setHeadlines(clip, headlines);
                        ClipStore.setIsHumanSentiment(clip, isHumanSentiment);
                        ClipStore.setIsTeased(clip, isTeased);
                        ClipStore.setKeywords(clip, keywords);
                        ClipStore.setMedium(clip, medium);
                        ClipStore.setNumberOfComments(clip, numberOfComments);
                        ClipStore.setNumberOfHighlights(clip, numberOfHighlights);
                        ClipStore.setNumberOfLikes(clip, numberOfLikes);
                        ClipStore.setOts(clip, ots);
                        ClipStore.setProgram(clip, program);
                        ClipStore.setPage(clip, page);
                        ClipStore.setPublicationDate(clip, publicationDate);
                        ClipStore.setSentiment(clip, sentiment);
                        ClipStore.setSocialShare(clip, socialShare);
                        ClipStore.setSourceIsoCode(clip, sourceIsoCode);
                        ClipStore.setSourceName(clip, sourceName);
                        ClipStore.setSummaries(clip, summaries);
                        ClipStore.setPreviewText(clip, text);
                        ClipStore.setCoded(clip, coded);
                        ClipStore.setMediaImpactScore(clip, mediaImpactScore);
                    });
                    var groupBy = model_1.searchModel.groupBy;
                    var isGroupBy = typeof groupBy === 'string' && groupBy.length > 0;
                    var getFacetName = function () {
                        var facetsSelected = model_1.facetsSelected;
                        var hasSelectedFacets = Array.isArray(facetsSelected) && facetsSelected.length > 0;
                        var lastFacet = hasSelectedFacets && facetsSelected[facetsSelected.length - 1];
                        var facetSplit = typeof lastFacet === 'string' &&
                            lastFacet.split(FACET_ITEM_KEY_SEPARATOR);
                        return (Array.isArray(facetSplit) &&
                            facetSplit.length > 1 &&
                            facetSplit[1]);
                    };
                    var facetName = '';
                    if (isGroupBy && groupBy === 'clipSelections.id') {
                        var cs = ClipSelectionStore.getClipSelectionById(model_1.searchModel.extendedGroupByFieldId);
                        facetName = (cs === null || cs === void 0 ? void 0 : cs.id) || '';
                    }
                    else {
                        facetName = getFacetName();
                    }
                    this.emitGetNextClips(data.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }), isGroupBy ? facetName : '0');
                }
                else {
                    this.emitError('GET_NEXT_CLIPS');
                }
                break;
            case ActionTypes.GET_RELATED_CLIPS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    var id = action.json.model.id;
                    var clip = this.getClipById(id);
                    ClipStore.setRelatedArticles(clip, data);
                    this.emitGetRelatedArticles(data.length);
                }
                break;
            case ActionTypes.SAVE_EVALUATION_RESPONSE:
                if (((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) != null &&
                    Array.isArray(action.json.data.updatedClips)) {
                    var updatedClips = action.json.data.updatedClips;
                    updatedClips.forEach(function (clipId) {
                        var clip = _this.getClipById(clipId);
                        ClipStore.setCoded(clip, true);
                    });
                    this.emitChange(updatedClips);
                }
                else {
                    this.emitError('SAVE_EVALUATION');
                }
                break;
            case ActionTypes.UPDATE_CLIP_RESPONSE:
                if (action.json) {
                    var _z = action.json, _0 = _z.model, author = _0.author, id = _0.id, ave = _0.ave, ots = _0.ots, keywords = _0.keywords, filesMV = _0.filesMV, mediaImpactScore = _0.mediaImpactScore, _1 = _z.data, headlines = _1.headlines, summaries = _1.summaries, text = _1.text, files = _1.files;
                    var clip = this.getClipById(id);
                    ClipStore.setAuthor(clip, author);
                    ClipStore.setKeywords(clip, keywords);
                    ClipStore.setHeadlines(clip, headlines);
                    ClipStore.setSummaries(clip, summaries);
                    ClipStore.setOts(clip, ots);
                    ClipStore.setMediaImpactScore(clip, mediaImpactScore);
                    ClipStore.setAve(clip, ave);
                    ClipStore.setTextWithHtml(clip, text);
                    var user = UserStore.getCurrentUser();
                    ClipStore.setEditFullTextHistory(clip, {
                        userName: "".concat(user.firstName).concat(user.lastName),
                        editDate: moment().utc().format('YYYYMMDDHHmmss'),
                        name: '',
                    });
                    ClipStore.setFiles(clip, files);
                    this.emitChange([id]);
                    this.emitUpdateClip(clip);
                }
                else {
                    this.emitError('UPDATE_CLIP');
                }
                break;
            case ActionTypes.UPDATE_LIKE_RESPONSE:
                if (action.json) {
                    var _2 = action.json, data = _2.data, id = _2.model.id;
                    var clip = this.getClipById(id);
                    var numberOfLikes = clip.numberOfLikes;
                    if (data) {
                        ClipStore.setNumberOfLikes(clip, numberOfLikes + 1);
                    }
                    else if (numberOfLikes > 0) {
                        ClipStore.setNumberOfLikes(clip, numberOfLikes - 1);
                    }
                    this.emitChange([id]);
                    this.emitUpdateLike();
                }
                else {
                    this.emitError('UPDATE_LIKE');
                }
                break;
            case ActionTypes.UPDATE_LIKES_RESPONSE:
                if (action.json && Array.isArray(action.json.data)) {
                    var _3 = action.json, data = _3.data, isLike_1 = _3.model.isLike;
                    var numberOfLikes_1;
                    data.forEach(function (_a) {
                        var id = _a.id, toIncrement = _a.toIncrement;
                        if (isLike_1 && !toIncrement) {
                            return;
                        }
                        var clip = _this.getClipById(id);
                        numberOfLikes_1 = clip.numberOfLikes;
                        if (isLike_1) {
                            ClipStore.setNumberOfLikes(clip, numberOfLikes_1 + 1);
                        }
                        else if (numberOfLikes_1 > 0) {
                            ClipStore.setNumberOfLikes(clip, numberOfLikes_1 - 1);
                        }
                    });
                    this.emitChange(data.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                    this.emitUpdateLikes();
                }
                else {
                    this.emitError('UPDATE_LIKES');
                }
                break;
            case ActionTypes.UPDATE_FAVOURITE_RESPONSE:
                if (action.json) {
                    var id = action.json.model.id;
                    var clip = this.getClipById(id);
                    ClipStore.setIsFavourite(clip, action.json.data);
                    this.emitChange([id]);
                    this.emitUpdateFavourite();
                }
                else {
                    this.emitError('UPDATE_FAVOURITE');
                }
                break;
            case ActionTypes.UPDATE_FAVOURITES_RESPONSE:
                if (action.json) {
                    var model = action.json.model;
                    var isFavouriteValue_1 = model.isFavourite;
                    model.clips.forEach(function (_a) {
                        var id = _a.id;
                        var clip = _this.getClipById(id);
                        ClipStore.setIsFavourite(clip, isFavouriteValue_1);
                    });
                    this.emitChange(model.clips.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                    this.emitUpdateFavourites();
                }
                else {
                    this.emitError('UPDATE_FAVOURITES');
                }
                break;
            case ActionTypes.GET_FAVOURITES_RESPONSE:
                if (action.json && Array.isArray(action.json.data)) {
                    var _4 = action.json, data = _4.data, isFavourite_1 = _4.model.isFavourite;
                    data.forEach(function (_a) {
                        var id = _a.id, toIncrement = _a.toIncrement;
                        if (isFavourite_1 && !toIncrement) {
                            return;
                        }
                        var clip = _this.getClipById(id);
                        var isFavouriteValue = clip.isFavourite;
                        ClipStore.setIsFavourite(clip, isFavouriteValue);
                    });
                    this.emitChange(data.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                    this.emitGetFavourites();
                }
                else {
                    this.emitError('GET_FAVOURITES');
                }
                break;
            case ActionTypes.UPDATE_SENTIMENTS_RESPONSE:
                if (action.json) {
                    var _5 = action.json.model, clips = _5.clips, value_1 = _5.value;
                    clips.forEach(function (_a) {
                        var id = _a.id;
                        var clip = _this.getClipById(id);
                        ClipStore.setIsHumanSentiment(clip, true);
                        ClipStore.setSentiment(clip, value_1);
                    });
                    this.emitChange(clips.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                    this.emitUpdateSentiments(value_1);
                }
                else {
                    this.emitError('UPDATE_SENTIMENTS');
                }
                break;
            case ActionTypes.UPDATE_TRASH_RESPONSE:
                if (action.json) {
                    var clips = action.json.model.clips;
                    this.emitChange(clips.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                    this.emitUpdateTrash(clips.map(function (_a) {
                        var id = _a.id;
                        return id;
                    }));
                }
                else {
                    this.emitError('UPDATE_TRASH');
                }
                break;
            case ActionTypes.TRANSLATE_CLIP_RESPONSE:
                if ((_c = action.json) === null || _c === void 0 ? void 0 : _c.data) {
                    var _6 = action.json.model, id = _6.id, langTo = _6.langTo;
                    var clip = this.getClipById(id);
                    ClipStore.setClipTranslation(clip, action.json.data.translation, langTo);
                    this.emitGetClip(id);
                    this.emitTranslation(action.json.data);
                }
                else {
                    this.emitError('TRANSLATE_CLIP');
                }
                break;
            case ActionTypes.GET_LANGUAGES_RESPONSE:
                if ((_d = action.json) === null || _d === void 0 ? void 0 : _d.data) {
                    this.setLanguages(action.json.data);
                    this.emitGetLanguagesListener();
                }
                else {
                    this.emitError('GET_LANGUAGES');
                }
                break;
            case ActionTypes.GET_MICROSOFT_LANGUAGES_RESPONSE:
                if ((_e = action.json) === null || _e === void 0 ? void 0 : _e.data) {
                    this.setMicrosoftLanguages(action.json.data);
                    this.emitGetMicrosoftLanguagesListener();
                }
                else {
                    this.emitError('GET_MICROSOFT_LANGUAGES');
                }
                break;
            default:
                break;
        }
    };
    return ClipStore;
}(BaseStore));
export default new ClipStore();
