var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var draftKeys = {
    all: [{ scope: 'draft' }],
    lists: function () { return [__assign(__assign({}, draftKeys.all[0]), { section: 'list' })]; },
    listDraft: function () {
        return [
            __assign(__assign({}, draftKeys.lists()[0]), { entity: 'drafts' }),
        ];
    },
    draft: function (id) {
        return [__assign(__assign({}, draftKeys.all[0]), { section: 'detail', id: id })];
    },
};
export { draftKeys };
