/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import ClipStore from 'stores/ClipStore';
import ClipSelectionStore from 'stores/ClipSelectionStore';
import CommonStore from 'stores/CommonStore';
import CopyrightStore from 'stores/CopyrightStore';
import CustomMetricsStore from 'stores/CustomMetricsStore';
import DashboardStore from 'stores/DashboardStore';
import FacetStore from 'stores/FacetStore';
import HierarchyStore from 'stores/HierarchyStore';
import PermissionStore from 'stores/PermissionStore';
import SearchStore from 'stores/SearchStore';
import UserStore from 'stores/UserStore';
import CommentStore from 'stores/CommentStore';
import HighlightStore from 'stores/HighlightStore';
import NewWidgetStore from 'stores/NewWidgetStore';
import ReviewStore from 'stores/ReviewStore';
import WidgetStore from 'stores/WidgetStore';
import PercolatorStore from 'stores/PercolatorStore';
import { initiateIntercom } from 'services/AppUtils';
import config from 'constants/parameters';
import { queryClient } from '../../src/App';
/**
 * reset all the stores when loging out or changing account
 * @params {object} options - options
 * @params {boolean} options.isLogout - true in case of a logout
 */
export function resetStores(_a) {
    var _b = _a === void 0 ? { isLogout: false } : _a, isLogout = _b.isLogout;
    ClipStore.reset();
    ClipSelectionStore.reset();
    CommonStore.reset();
    CopyrightStore.reset();
    DashboardStore.reset();
    FacetStore.reset();
    HierarchyStore.reset();
    PermissionStore.reset();
    SearchStore.reset();
    CommentStore.reset();
    CustomMetricsStore.reset();
    HighlightStore.reset();
    NewWidgetStore.reset();
    ReviewStore.reset();
    WidgetStore.reset();
    UserStore.resetOnSwitchAccount();
    PercolatorStore.reset();
    // eslint-disable-next-line
    queryClient.invalidateQueries({
        predicate: function (query) { return query.queryKey[0].scope !== 'translation'; },
    });
    if (isLogout) {
        UserStore.reset();
    }
}
export function logoutAction() {
    window.localStorage.removeItem('LoginSSO');
    window.localStorage.removeItem('SESSION');
    window.localStorage.removeItem('UserSession');
    window.location.replace("".concat(config.API_ROOT, "/api/Access/LogOff"));
}
export function logOut(_a) {
    var _b = _a === void 0 ? { isInactive: false } : _a, isInactive = _b.isInactive;
    ClipStore.initializeState();
    localStorage.removeItem('SESSION');
    localStorage.removeItem('UserSession');
    localStorage.removeItem('fullArticleView');
    localStorage.removeItem('searchModel');
    localStorage.removeItem('isFilterOpen');
    localStorage.removeItem('chechHierarchy');
    localStorage.removeItem('userLogin');
    localStorage.removeItem('checkSession');
    localStorage.removeItem('sessionTime');
    localStorage.removeItem('primary-background-color');
    localStorage.removeItem('primary-text-color');
    localStorage.removeItem('secondary-background-color');
    localStorage.removeItem('secondary-text-color');
    resetStores({ isLogout: true });
    // Logout from Intercom and Reinitiate
    window.Intercom('shutdown');
    initiateIntercom();
    if (isInactive) {
        window.location.replace('/login?inactive=true');
    }
}
