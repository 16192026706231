import { useMutation } from '@tanstack/react-query';
import { postFetchQuery } from '@services/AppUtils';
import Dispatcher from '@services/AppDispatcher';
import ActionTypes from '@constants/actionTypes';
import PermissionActionCreators from 'actions/PermissionActionCreators';
var useLoginWithTicket = function (params) {
    return useMutation({
        mutationFn: function (model) {
            return postFetchQuery('/api/Access/LoginWithTicket', model, true);
        },
        onSuccess: function (result, model) {
            if (result) {
                if (params === null || params === void 0 ? void 0 : params.onSuccess)
                    params.onSuccess(result);
                // TODO remove the dispatcher after UserStore migration to react-query
                PermissionActionCreators.getPermissions();
                Dispatcher.handleServerAction({
                    actionType: ActionTypes.GET_LOGIN_RESPONSE,
                    errors: {},
                    json: result,
                });
            }
        },
    });
};
export default useLoginWithTicket;
